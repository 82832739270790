<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>商城管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/stock' }">图书库存</el-breadcrumb-item>
                <el-breadcrumb-item>新建图书</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="mini">

                <el-form-item label="名称" label-position="left" prop="name" class="input">
                    <el-input style="width: 500px" v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="封面：" label-position="left" prop="pic">
                    <cover :uploadPath="uploadPath" @success="uploadSuccess"></cover>
                </el-form-item>

                <el-form-item label="库存" label-position="left" prop="stockNums">
                    <el-input style="width: 500px" v-model.number="form.stockNums"></el-input>
                </el-form-item>

                <el-form-item label="图书状态" label-position="left" class="input" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio-button label="上架"></el-radio-button>
                        <el-radio-button label="下架"></el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit('form')">创建</el-button>
                    <router-link to="/stock" class="router-link">
                        <el-button style="margin-left: 10px" size="small">取消</el-button>
                    </router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import cover from '../../../components/cover'
import { mapActions } from "vuex";
export default {
    name: "Add",
    components: { cover },
    data() {
        return {
            //表单数据
            form: {
                status: '上架'
            },
            //验证
            rules: {
                name: [
                    { required: true, message: '请输入课程名称', trigger: 'blur' },
                ],
                pic: [
                    { type: 'string', required: true, message: '请选择图片封面', trigger: 'change' }
                ],
                stockNums: [
                    { required: true, message: '请输入库存数量', trigger: 'blur' },
                    { type: 'number', message: '库存必须为数字值' }
                ],
                status: [
                    { required: true, message: '请选择图书状态', trigger: 'change' },
                ],
            },
            //图书状态
            types: [
                {
                    id: 1,
                    name: '上架'
                },
                {
                    id: 2,
                    name: '下架'
                }
            ],
            //加载动画
            loading: false,
            uploadPath: 'teacherCourse/coursePic',
        }
    },
    methods: {
        ...mapActions('store', ['addBookStock']),
        //上传图片
        uploadSuccess(path) {
            this.form.pic = path
        },
        //保存表单
        onSubmit(formName) {
            this.form.status = this.form.status == '上架' ? 1 : 2
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addBookStock(this.form).then(res => {
                        if (res.res_info == 'ok') {
                            this.$router.go(-1);
                            this.$message.success('创建成功')
                        }
                    })
                } else {
                    return false;
                }
            });
        },
    },
}
</script>
